.container .accordion-button:not(.collapsed) {
  color: rgb(33, 37, 41);
  background-color: rgb(255, 255, 255);
}

.container .accordion-button {
  font-weight: bold;
}

.list-group-item:hover {
  background-color: hsl(0, 0%, 95%);
}


/* PTJ theming */
:root {
  --ptj-blue: #0980a7;
  --ptj-blue-dark: #0a7193;
  --ptj-blue-rgb: 9, 128, 167;
  --bs-dark-rgb: var(--ptj-blue-rgb) !important;
  --bs-primary-rgb: var(--ptj-blue-rgb) !important;
}

.app-logo {
  width: 225px;
}

nav.navbar.ptj-navbar .navbar-brand {
  color: rgb(var(--ptj-blue-rgb));
}

.list-group .list-group-item.active {
  background-color: rgb(var(--ptj-blue-rgb));
}

button.btn-primary {
  --bs-btn-bg: var(--ptj-blue);
  --bs-btn-hover-bg: var(--ptj-blue-dark);
  --bs-btn-active-bg: var(--ptj-blue);
}